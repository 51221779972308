import React, { useState }  from 'react';
import { Row, Form, Col, Button, Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { SketchPicker } from 'react-color';

class AddScreen extends React.Component {

  constructor(props) {
    super(props);
    this.initialState = {
      uid: '',
      name: '',
      description: '',
      status: 'active',
      published: true,
      admins: '',
      moderators: '',
      logo: null,
      isPublic: true,
      screenwidth: '',
      screenheight: '',
      bluetoothids: '',
      headtext: 'Partagez en Live sur cet écran',
      bottomtext: 'Installez l\'application Wall\'IT sur Android ou iOS et scannez le code sur le côté pour partager vos contenus',
      backgroundcolor: '#765b2c',
      textcolor: '#e3991d',
      publishOptionsPicture: false,
      publishOptionsGiphy: false,
      publishOptionsText: false
    }

    this.state = {
      src: null,
      cropSrc: null,
      crop: {
        unit: "px",
        x:10,
        y: 10,
        width: 50,
        height: 50,
        minWidth: 50,
        maxWidth: 50,
        minHeight: 50,
        maxHeight: 50
      },
    };
    console.log(this.props.screen)
    if(this.props.screen){
      this.state = this.props.screen
      this.state.publishOptionsPicture = this.props.screen.publishOptions && this.props.screen.publishOptions.picture ? this.props.screen.publishOptions.picture : false
      this.state.publishOptionsGiphy = this.props.screen.publishOptions && this.props.screen.publishOptions.giphy ? this.props.screen.publishOptions.giphy : false
      this.state.publishOptionsText = this.props.screen.publishOptions && this.props.screen.publishOptions.text ? this.props.screen.publishOptions.text : false

    } else {
      this.state = this.initialState;
    }

    this.showModal = false;
    this.selectedImage = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleChangeBackgroundColor = this.handleChangeBackgroundColor.bind(this);
    this.handleChangeTextColor = this.handleChangeTextColor.bind(this);
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    console.log(name + " | " + value)

    this.setState({
      [name]: value
    })
    if (name == 'logo') {
      this.onSelectFile(event);
    }
  }

  handleCheckbox(event) {
    const name = event.target.name;
    console.log(name)
    const value = event.target.checked;
      this.setState({
        [name]: value
      })
  }

  handleChangeBackgroundColor = (color) => {
    this.setState({ backgroundcolor: color.hex });
  };

  handleChangeTextColor = (color) => {
    this.setState({ textcolor: color.hex });
  };

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state)
    if (this.state.blob) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.state.logo = reader.result
        this.state.src = null;
        this.props.onFormSubmit(this.state);
        this.setState(this.initialState);
      }
      );
      reader.readAsDataURL(this.state.blob);

    } else {
      delete this.state.logo;
      this.state.src = null;
      this.props.onFormSubmit(this.state);
      this.setState(this.initialState);
    }
  }

  handleReset(event) {
    this.setState(this.initialState);
    console.log(this.state)

    this.props.onFormSubmit(null);
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      this.selectedImage = true;
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    crop.width = 50;
    crop.height = 50;
    this.setState({ crop });

  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      console.log(croppedImageUrl)
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = 50;
    canvas.height = 50;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        this.setState({ blob: blob });
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    console.log(this.state)
    let pageTitle;
    if(this.state._id) {
      pageTitle = <h2>Edit Screen</h2>
    } else {
      pageTitle = <h2>Add Screen</h2>
    }
    this.showModal = true;
    return(
      <Modal
        show={this.showModal}
        onHide={() => {this.showModal = false;}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={true}
      >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {pageTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>

        <Row>
          <Col sm={6}>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="name">
                <Form.Label>Screen Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={this.state.name || ''}
                  onChange={this.handleChange}
                  placeholder="Screen Name"/>
              </Form.Group>
                <Form.Group controlId="uid">
                  <Form.Label>Screen UID</Form.Label>
                  <Form.Control
                    type="text"
                    name="uid"
                    value={this.state.uid || ''}
                    onChange={this.handleChange}
                    placeholder="Screen UID"/>
                </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Desciption</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={this.state.description || ''}
                  onChange={this.handleChange}
                  placeholder="Description" />
              </Form.Group>
              <Form.Group controlId="screensize">
                <Form.Label>Taille de l'écran</Form.Label>
                <Form.Control
                  type="text"
                  name="screenwidth"
                  value={this.state.screenwidth || ''}
                  onChange={this.handleChange}
                  placeholder="largueur, ex : 1920" />
                <Form.Control
                  type="text"
                  name="screenheight"
                  value={this.state.screenheight || ''}
                  onChange={this.handleChange}
                  placeholder="hauteur, ex : 1080" />
              </Form.Group>
              <Form.Group controlId="bluetoothids">
                <Form.Label>Bluetooth Id</Form.Label>
                <Form.Control
                  type="text"
                  name="bluetoothids"
                  value={this.state.bluetoothids || ''}
                  onChange={this.handleChange}
                  placeholder="C0:84:7D:D3:1A:D5" />
              </Form.Group>
              <Form.Group controlId="status">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={this.state.status || ''}
                  onChange={this.handleChange}>
                    <option value="active" selected={this.state.status == 'active' ? true : false}>Activé</option>
                    <option value="suspended" selected={this.state.status == 'suspended' ? true : false}>Suspendu</option>
                    <option value="archived" selected={this.state.status == 'archived' ? true : false}>Archivé</option>
                  </Form.Control>
              </Form.Group>
              <Form.Group controlId="published">
                <Form.Check
                  type="switch"
                  name="published"
                  label="En ligne"
                  defaultChecked={this.state.published}
                  onChange={this.handleCheckbox} />
              </Form.Group>
              <Form.Group controlId="admins">
                <Form.Label>Administrateurs</Form.Label>
                <Form.Control
                  type="text"
                  name="admins"
                  value={this.state.admins || ''}
                  onChange={this.handleChange}
                  placeholder="Administrateurs" />
              </Form.Group>
              <Form.Group controlId="moderators">
                <Form.Label>Modérateurs</Form.Label>
                <Form.Control
                  type="text"
                  name="moderators"
                  value={this.state.moderators || ''}
                  onChange={this.handleChange}
                  placeholder="Modérateurs" />
              </Form.Group>
              <Form.Group controlId="logo">
                <Form.Label>Logo</Form.Label>
                <Form.Control
                  type="file"
                  name="logo"
                  accept="image/*"
                  value={''}
                  onChange={this.handleChange}
                  placeholder="Logo" />
                  {
                    (this.state.logo && !this.selectedImage) &&
                    (<img src={atob(Buffer.from(this.state.logo).toString())} />)

                  }
                  {src && (
                      <ReactCrop
                        src={src}
                        crop={crop}
                        ruleOfThirds
                        circularCrop
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}
                    {croppedImageUrl && (
                      <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                    )}
              </Form.Group>
              <Form.Group controlId="isPublic">
                <Form.Label>Accès</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="isPublic"
                  label="Public"
                  defaultChecked={this.state.isPublic}
                  onChange={this.handleCheckbox}/>
              </Form.Group>
              <Form.Group controlId="headtext">
                <Form.Label>Titre en haut de l'écran</Form.Label>
                <Form.Control
                  type="text"
                  name="headtext"
                  value={this.state.headtext || ''}
                  onChange={this.handleChange}
                  placeholder="Titre en haut de l'écran" />
              </Form.Group>
              <Form.Group controlId="bottomtext">
                <Form.Label>Texte en bas de l'écran</Form.Label>
                <Form.Control
                  type="text"
                  name="bottomtext"
                  value={this.state.bottomtext || ''}
                  onChange={this.handleChange}
                  placeholder="Texte en bas de l'écran" />
              </Form.Group>
              <Form.Group controlId="backgroundcolor">
                <Form.Label>Couleur d'arrière plan</Form.Label>
                <Form.Control
                  type="hidden"
                  name="backgroundcolor"
                  value={this.state.backgroundcolor || ''} />
                  <SketchPicker
                      name="backgroundcolor"
                      color={ this.state.backgroundcolor }
                      onChangeComplete={ this.handleChangeBackgroundColor }
                    />
              </Form.Group>
              <Form.Group controlId="textcolor">
                <Form.Label>Couleur du texte</Form.Label>
                <Form.Control
                  type="hidden"
                  name="textcolor"
                  value={this.state.textcolor || ''} />
                  <SketchPicker
                      name="textcolor"
                      color={ this.state.textcolor }
                      onChangeComplete={ this.handleChangeTextColor }
                    />
              </Form.Group>
              <Form.Group controlId="publishOptions">
                <Form.Label>Options de publication</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="publishOptionsPicture"
                  label="Images"
                  defaultChecked={this.state.publishOptionsPicture || false}
                  onChange={this.handleCheckbox}/>
                  <Form.Check
                    type="checkbox"
                    name="publishOptionsGiphy"
                    label="Gif sur Giphy"
                    defaultChecked={this.state.publishOptionsGiphy || false}
                    onChange={this.handleCheckbox}/>
                    <Form.Check
                      type="checkbox"
                      name="publishOptionsText"
                      label="Texte"
                      defaultChecked={this.state.publishOptionsText || false}
                      onChange={this.handleCheckbox}/>
              </Form.Group>
              <Form.Group>
                <Form.Control type="hidden" name="_id" value={this.state._id} />
                <Button variant="success" type="submit">Save</Button>
                <Button variant="secondary" type="reset" onClick={this.handleReset}>Cancel</Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </div>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
      </Modal>
    )
  }
}

export default AddScreen;
