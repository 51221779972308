

const dev = {
    "api": {
        "url": "http://devadmin.shareif.space/api"
    }
};

const prod = {
    "api": {
        "url": "https://prod.shareif.space/api"
    }
};


const config = process.env.REACT_APP_STAGE === 'dev'
  ? dev
  : prod;

export default config;
